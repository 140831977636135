import { assetsUrl } from '@/config';

export const FilePathUtils = {
  images: () => `${assetsUrl}/assets/images`,
  courseImagePath: (id: number) =>
    `${assetsUrl}/uploads/courses/${id}/thumbnails`,
  userProfilePath: (id: number) => `${assetsUrl}/uploads/users/${id}/profile`,
  resourcePath: (id: number) =>
    `${assetsUrl}/uploads/resources/${id}/documents`,
  programImagePath: (id: number) =>
    `${assetsUrl}/uploads/programs/${id}/thumbnails`,
};
