'use client';

import { Input } from '@tajdid/tajdid-ui';
import { Button } from '@tajdid/tajdid-ui';
import { CallIcon, Location05Icon, MailOpen02Icon } from 'hugeicons-react';
import Image from 'next/image';
import { Logo } from '../logo';
import { usePathname } from '@/i18n/routing';

export default function Footer() {
  const pathname = usePathname();
  const isContentsPage =
    pathname.includes('contents') ||
    pathname.includes('quizzes') ||
    pathname.includes('resources');

  return (
    <>
      {!isContentsPage && (
        <footer className="bg-primary-900 py-15 rounded-t-md">
          <div className="container mx-auto px-4 lg:px-0 flex flex-col lg:flex-row justify-between gap-8">
            <div>
              <div className="mb-4">
                <Logo variant="white" width={175} height={34} />
              </div>
              <ul className="space-y-2 text-base font-normal text-gray-300">
                <div className="flex items-center gap-2">
                  <Location05Icon
                    height={20}
                    width={20}
                    className="text-primary-300"
                  />
                  <li>1 Brighton Road, Surbiton, United Kingdom KT6 5LX</li>
                </div>
                <div className="flex items-center gap-2">
                  <CallIcon
                    height={20}
                    width={20}
                    className="text-primary-300"
                  />
                  <li>+447405755820</li>
                </div>
                <div className="flex items-center gap-2">
                  <MailOpen02Icon
                    height={20}
                    width={20}
                    className="text-primary-300"
                  />
                  <li>academysuite@example.com</li>
                </div>
              </ul>
              <div className="flex space-x-2 mt-4">
                <Image
                  src="/Images/Fb.png"
                  width={32}
                  height={32}
                  alt="facebook"
                />
                <Image
                  src="/Images/Google.png"
                  width={32}
                  height={32}
                  alt="facebook"
                />
              </div>
            </div>

            <div>
              <h4 className="font-semibold text-white text-xl mb-[22px]">
                Company
              </h4>
              <ul className="grid gap-4 text-base font-normal text-gray-300">
                <li>About Us</li>
                <li>Careers</li>
                <li>Blog</li>
                <li>Pricing</li>
              </ul>
            </div>

            <div>
              <h4 className="font-semibold text-white text-xl mb-[22px]">
                Resources
              </h4>
              <ul className="grid gap-4 text-base font-normal text-gray-300">
                <li>Templates</li>
                <li>Tutorials</li>
                <li>Free resources</li>
                <li>Contract templates</li>
              </ul>
            </div>

            <div>
              <h4 className="font-semibold text-white text-xl mb-[22px]">
                Join Our Newsletter
              </h4>
              <div className="bg-white p-1 flex rounded-sm">
                <Input
                  type="email"
                  placeholder="Email Address"
                  inputClassName="border-none rounded-sm"
                  className="rounded-sm"
                />
                <Button className="bg-primary-500 hover:bg-primary-700 text-white px-6">
                  Join For Free
                </Button>
              </div>
            </div>
          </div>

          <div className="container mx-auto px-4 lg:px-0 py-4 md:py-[56px]">
            <div className="w-full h-[1px] bg-primary-700"></div>
          </div>

          <div className="text-center text-sm text-gray-400">
            Copyright ©2022. All Rights Reserved.
          </div>
        </footer>
      )}
    </>
  );
}
